<div *ngIf="isMobile" class="container" (click)="openNav()">
  <div class="bars"></div>
</div>
<div class="info-container">
  <div class="info">
    <h1>Lam Nguyen</h1>
    <span>Full Stack Developer</span>
  </div>

  <ul *ngIf="!isMobile" class="horizontal-menu">
    <li><a href="#home" (click)="closeNav()">Home</a></li>
    <li><a href="#about" (click)="closeNav()">About</a></li>
    <li><a href="#projects" (click)="closeNav()">Projects</a></li>
    <li><a href="#contact" (click)="closeNav()">Contact</a></li>
  </ul>
</div>

<nav>
  <div class="close" (click)="closeNav()">
    <div></div>
  </div>
  <ul>
    <li><a href="#home" (click)="closeNav()">Home</a></li>
    <li><a href="#about" (click)="closeNav()">About</a></li>
    <li><a href="#projects" (click)="closeNav()">Projects</a></li>
    <li><a href="#contact" (click)="closeNav()">Contact</a></li>
  </ul>
</nav>
