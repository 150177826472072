<section id="hero" class="hero">
<!--  <video autoplay muted loop class="video-background">-->
<!--    <source src="assets/digitalBg.mp4" type="video/mp4">-->
<!--    Your browser does not support the video tag.-->
<!--  </video>-->
  <div class="hero-content">
    <h1>Hi, I'm Lam, <span class="highlight">a developer</span></h1>
    <div class="cta-buttons">
      <a href="#work" class="btn btn-primary">See my Work</a>
      <a href="#contact" class="btn btn-secondary">Contact Me</a>
    </div>
  </div>
</section>
