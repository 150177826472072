<section id="projects" class="projects">
    <h2>My Projects</h2>
    <div class="project-list">
      <div class="project" *ngFor="let project of projects">
        <h3>{{ project.name }}</h3>
        <p>{{ project.description }}</p>
        <a [href]="project.link" target="_blank" class="btn">View Project</a>
      </div>
    </div>
  </section>
  