import { Component, AfterViewInit, HostListener } from '@angular/core';
import { gsap } from 'gsap';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements AfterViewInit {
  isMobile: boolean = false;
  private tl = gsap.timeline({ defaults: { duration: 1, ease: 'expo.inOut' }, paused: true });

  ngOnInit(): void {
    this.checkScreenSize();
  }

  // Check screen size on component initialization
  checkScreenSize(): void {
    this.isMobile = window.matchMedia('(max-width: 768px)').matches;
    console.log("isMobile: ", this.isMobile);
  }

  // Listen to window resize events to update screen size dynamically
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkScreenSize();
  }

  ngAfterViewInit(): void {
    // Setting initial state to a tiny size
    gsap.set('nav', { width: '1px', height: '1px', opacity: 0 });

    this.tl.to('nav', { width: '100vw', height: '100vh', opacity: 1, duration: 2.5 }) // Increase duration to 2.5 seconds
      .to('nav ul li a', { opacity: 1, pointerEvents: 'all', stagger: 0.3, duration: 0.8 }, '-=1') // Increase stagger and duration
      .to('.close', { opacity: 1, pointerEvents: 'all', duration: 0.5 }, '-=1')
      .to('nav h2', { opacity: 1, duration: 0.5 }, '-=1')
      .pause(); // Start paused
  }

  openNav(): void {
    setTimeout(() => {
      this.tl.play();  // Trigger animation after nav becomes visible
    }, 0);
  }

  closeNav(): void {
    this.tl.reverse();
    this.tl.eventCallback("onReverseComplete", () => {
    });
  }
}
