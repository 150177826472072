import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {
  projects = [
    { name: 'ProFit', description: 'Fitness app that allows users track pogress, fintess calculation,get  workout plans and suggestions with different APIs and ChatGPT.', link: 'https://github.com/lamnguyen124/AiFitness-UI' },
    { name: 'Drink Hustle', description: 'Facilitates the hiring of bartenders by event organizers. Engaged in editing, designing, and implementing search and filter functionalities throughout the application.', link: 'https://github.com/Wait-Life/Capstone' },
    { name: 'Adlister', description: 'Created an ads database that enables users to register, view, and manage their ads. Utilized Java for programming delete and edit functions within the MySQL database, and actively contributed to front-end design and development.', link: 'https://github.com/Kyle-Jose-Lam/LamLister-and-the-crew' },
  ];

  constructor() { }

  ngOnInit(): void { }
}
